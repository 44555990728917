import React, { Component } from 'react';
import Navigation from './components/Navigation';
import MainContent from './components/MainContent';
import './scss/base.scss';
import IconLocation from './assets/icon-location.svg';


class App extends Component {
  state = {
      currentView: 'Home'
  }
x
  // Keep track of the current View within MainContent Component
  findCurrentView = view => { this.setState({ currentView: view }) }
  
  // Scrolls to view upon nav icon click
  onClickScroll = (e) => {
    const icon = e.currentTarget.getAttribute('data-key')
    const scrollToView = document.getElementById(`${icon}`)
    scrollToView.scrollIntoView();
  }

  

  render() {
    return (
      <div>
        <div id="app">
          <Navigation currentView={this.state.currentView} onClick={this.onClickScroll} />
          <div className="decoyNav"></div>
          <MainContent findCurrentView={this.findCurrentView} />
        </div>
        <p className="location">Kansas City, Missouri<img src={IconLocation} alt="Located in Kansas City, Missouri" className="location-icon"/></p>
        {/* <p className="copyright">Created with React.js</p>
        <p className="scrolldown">&#x2190;&nbsp;&nbsp;Scroll Down</p> */}
      </div>
      
      )
    }
  }
  
  export default App;