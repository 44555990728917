import React from 'react';

class HamburgerIcon extends React.Component {
    state = {
        active: ' '
    }

    handleClick = () => {
        this.props.onClick()
        let isActive = this.state.active === ' ' ? 'active' : ' ';
        this.setState({ active: isActive });
    }

    render() {
        return (
            <div className={`hamburger-icon ${this.state.active}`} onClick={this.handleClick}>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
            </div>
        )
    }
}

export default HamburgerIcon;