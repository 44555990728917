import React from 'react';

const ResideKcContent = () => {
    return (
        <div className="project-overlay" id="reside">
            <a href="http://reside-kc.herokuapp.com/" rel="noopener noreferrer"  target="_blank">
            <p>Reside KC <em>(In Development)</em></p>
            <ul>
                <li><strong>Role</strong>: Lead Front-end Developer & Designer</li>
                <li><strong>Technology</strong>: React.js, Google Maps API, KC Data API, Axios, Express.js, Heroku</li>   
                <li style={{paddingTop: 30, fontSize: 19, textDecoration: 'underline'}}>Click to view project</li>  
            </ul>
            </a>
        </div>  
    )
}

export default ResideKcContent;