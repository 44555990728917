import React from 'react';
import ResideKcImage from '../assets/mockup-residekc.jpg';
import ResideKcContent from '../components/ResideKcContent';

import HomeBySonjaImage from '../assets/mockup-home-by-sonja.png';
import HomeBySonjaContent from '../components/HomeBySonjaContent';

import MortgageLandingPagesImage from '../assets/sample-project-2.jpeg';
import MortgageLandingPagesContent from '../components/MortgageLandingPagesContent';

import SummitLPImage from '../assets/summit-lp.png';
import SummitLPContent from '../components/SummitLPContent';


import ReactTrelloBoardImage from '../assets/react-trello-board.png';
import ReactTrelloBoardContent from '../components/ReactTrelloBoardContent';

import CreativePortfolioImage from '../assets/creative-portfolio.png';
import CreativePortfolioContent from '../components/CreativePortfolioContent';

class PortfolioItem extends React.Component {

    componentDidMount() {
        let project = document.querySelector('.project');
        project.style.cssText = ('opacity: 1; visibility: visible')
    }


    render() {
        let ProjectImage;
        let ProjectContent;

        switch(this.props.project) {
            case 'project1':
                ProjectImage = HomeBySonjaImage;
                ProjectContent = <HomeBySonjaContent />;
                break;
            case 'project2':
                ProjectImage = MortgageLandingPagesImage;
                ProjectContent = <MortgageLandingPagesContent />;
                break;
            case 'project3':
                ProjectImage = SummitLPImage;
                ProjectContent = <SummitLPContent />;
                break;
            case 'project4':
                ProjectImage = ResideKcImage;
                ProjectContent = <ResideKcContent />;
                break;
            case 'project5':
                ProjectImage = ReactTrelloBoardImage;
                ProjectContent = <ReactTrelloBoardContent />;
                break;
            default:
                ProjectImage = CreativePortfolioImage;
                ProjectContent = <CreativePortfolioContent />;
        }
        

        return(
            <section className="section-container" id="project">
            <div className="project">
                <div>
                    <div className="browser-window">
                        <div className="browser-topbar">
                            <div className="browser-btn close"></div>
                            <div className="browser-btn shrink"></div>
                            <div className="browser-btn enlarge"></div>
                        </div>
                        <img src={ProjectImage} alt="project" className="browser-image" />
                    </div> 
                </div>
                <div className="project-description">
                    {ProjectContent}
                    <button onClick={this.props.changeToGrid} className="project-button">Back to Projects</button>
                </div>
            </div>
            </section>     
         )
    }
}

export default PortfolioItem;