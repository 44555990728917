import React from 'react';

const MortgageLandingPagesContent = () => {
    return (
        <div className="project-overlay">
            <a href="https://app.softvu.com/page/09205fb9456a473fa359dddb04034c2d/9f7ccd7d0a3a462b8159be823d22f27c" rel="noopener noreferrer"  target="_blank">
            <p>Mortgage Lender Pages</p>
            <ul>
                <li><strong>Role</strong>: Front-end Developer & Designer</li>
                <li><strong>Technology</strong>: React, Node, SCSS</li>
                <li style={{paddingTop: 30, fontSize: 19, textDecoration: 'underline'}}>Click to view project</li>      
            </ul>
            </a>
        </div>  
    )
}

export default MortgageLandingPagesContent;