import React, { Component } from 'react';
import HomeBySonjaContent from '../components/HomeBySonjaContent';
import MortgageLandingPagesContent from '../components/MortgageLandingPagesContent'
import SummitLPContent from '../components/SummitLPContent';
import ResideKcContent from '../components/ResideKcContent';
import ReactTrelloBoardContent from '../components/ReactTrelloBoardContent';
import CreativePortfolio from '../components/CreativePortfolioContent';

class PortfolioGrid extends Component {
    state = {
        portfolioPieces: [ 
            <CreativePortfolio />,
            <SummitLPContent />,   
            <MortgageLandingPagesContent />, 
            <ResideKcContent />, 
            <ReactTrelloBoardContent />,
            <HomeBySonjaContent />   
        ]
    }


    

    render() {
        return(
            <div className="grid-container"> 
                {this.state.portfolioPieces.map((project, key) => (
                    
                    <div id={ 'project' + (key+1) } key={key} onClick={this.props.onClick} className="grid-item">
                        {this.state.portfolioPieces[key]}
                    </div>
                    
                ))}
            </div>
         )
    }
}

export default PortfolioGrid;