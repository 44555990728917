import React from 'react';

const SummitLPContent = () => {
    return (
        <div className="project-overlay">
            <a href="https://www.softvu.com/summitx/" rel="noopener noreferrer"  target="_blank">
            <p>2018 Mortgage Innovation Summit</p>
            <ul>
                <li><strong>Role</strong>: Front-end Developer & Designer</li>
                <li><strong>Technology</strong>: HTML5, CSS3, JavaScript, Wordpress, PHP</li>    
                <li style={{paddingTop: 30, fontSize: 19, textDecoration: 'underline'}}>Click to view project</li>  
            </ul>
            </a>
        </div>  
    )
}

export default SummitLPContent;