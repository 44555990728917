import React, { Component } from 'react';
import ProfileImage from '../assets/profile-picture.jpg';

class About extends Component {


    render() {
        return (
            <div className="section-container" id="About">
                <div className="content">
                    <h1>About Me</h1>
                    <p>Hello, I’m Josh Zeilmann, a creative Web Developer and UI Designer based in Kansas City, Missouri. I’ve been tinkering with the web since the days of custom Myspace themes and flash game websites. Fast forward to present day, I am focused on creating powerful web solutions for businesses of all sizes! JavaScript, specifically React and Node, are my current focus, but I believe in using the right tool for the job, not necessarily what is trending. A disciple of continuous learning, I strive to experiment with new technologies and workflows to remain sharp and offer my clients the best solutions for their evolving web problems. I like meeting people in my field and having an active presence in the development community, through meet-ups and hack-a-thons. I’m always interested in hearing about new project or ideas, so feel free to shoot me a message if you want to grab a cup of coffee!</p>
                </div>
                <img src={ProfileImage} alt="Josh Zeilmann" />
            </div>
        )
    }
}

export default About;

