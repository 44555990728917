import React from 'react';

const CreativePortfolioContent = () => {
    return (
        <div className="project-overlay">
            <a href="https://heuristic-engelbart-7b0d5e.netlify.com/" rel="noopener noreferrer"  target="_blank">
            <p>SoftVu Creative Portfolio <em>(In Development)</em></p>
            <ul>
                <li><strong>Role</strong>: Designer & Developer</li>
                <li><strong>Technology</strong>: React.js, Node.js, SCSS, Bodymoving.js (SVG Animation)</li> 
                <li style={{paddingTop: 30, fontSize: 19, textDecoration: 'underline'}}>Click to view project</li>     
            </ul>
            </a>
        </div>  
    )
}

export default CreativePortfolioContent;