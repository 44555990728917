import React, { Component } from 'react';
import Home from '../components/Home';
import About from '../components/About';
import Portfolio from '../components/Portfolio';
import Contact from '../components/Contact';


class MainContent extends Component {
    state = {
        currentView: 'Home',
        scrolled: 0
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onWindowScroll); 
    }
     
     componentWillUnmount() {
       window.removeEventListener('scroll', this.onWindowScroll);
    }

    aboutScrollEffect = () => {
        const content = document.querySelector('#About .content');
        const img = document.querySelector('#About img'); 
        const boundingBox = content.getBoundingClientRect();
        const elTop = boundingBox.top;
        const elBottom = boundingBox.bottom;
        console.log(`Element Top ${elTop}`);
        console.log(`Element Bottom ${elBottom}`);
        console.log(`Window Inner Height ${window.innerHeight}`);
        
        if (elTop <= 200) {
            console.log(content);
            content.style.cssText = ('transform: translate(0px);');
            img.style.cssText = ('transform: scale(1);' );   
        } else { content.style.cssText = ('transform: translate(-800px);');img.style.cssText = ('transform: scale(1.6);' );  }
    }
    
    // On mobile, show overlay to grid block in view
    isScrolledIntoView = () => {
        const getGridItems = Array.from(document.querySelectorAll('.project-overlay'));
        for (let i = 0; i < getGridItems.length; i++) {
            let el = getGridItems[i];
            const boundingBox = el.getBoundingClientRect();
            const elTop = boundingBox.top;
            const elBottom = boundingBox.bottom;
            if ((elTop >= 50) && (elBottom <= (window.innerHeight - 50))) {
                getGridItems[i].style.cssText = ('background-color: rgba(27, 198, 180,.7);');
                let ul = getGridItems[i].getElementsByTagName('ul');
                ul[0].style.cssText = ('opacity: 1;');
            } else {
                getGridItems[i].style.cssText = ('background-color: transparent;');
                let ul = getGridItems[i].getElementsByTagName('ul');
                ul[0].style.cssText = ('opacity: 0;');
            }
        }
        
        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        // return isVisible;
    }

    onWindowScroll = () => { 
        window.innerWidth < 768 ? this.isScrolledIntoView() : this.aboutScrollEffect();
        const scrollAmount = window.scrollY;
        // console.log(`Scrolled Amount ${this.state.scrolled}`)

        const getHome = document.querySelector('#Home .content');
        getHome.style.cssText = `opacity: ${1 - (this.state.scrolled / 350)} !important;`
        // let getImage = document.querySelector('#About img')
        // this.state.scrolled > 250 ?  getImage.style.cssText = `width: ${this.state.scrolled / 10}%;` : getImage.style.cssText = `width: 0%;`;

        
        const windowHeight = window.innerHeight;
        this.setState({scrolled: scrollAmount})
        if (scrollAmount < (windowHeight)) {
            this.setState({ currentView: 'Home' })
            this.props.findCurrentView('Home');
        }
        else if (scrollAmount > windowHeight && scrollAmount < (windowHeight * 2)) {
            this.setState({ currentView: 'About' })
            this.props.findCurrentView('About');
        }
        else if (scrollAmount > (windowHeight * 2) && scrollAmount < (windowHeight * 3)  ) {
            this.setState({ currentView: 'Portfolio' })
            this.props.findCurrentView('Portfolio');
        }
        else if (scrollAmount >= (windowHeight * 3) ) {
            this.setState({ currentView: 'Contact' })
            this.props.findCurrentView('Contact');
        }
    }

    render() {
        return(  
            <div id="page-scroller">
                <Home scrollAmount={this.state.scrolled} goToPage={this.goToPage} />
                <About />
                <Portfolio />
                <Contact />
            </div>
         )
    }
}

export default MainContent;