import React, { Component } from 'react';
import PortfolioGrid from '../components/PortfolioGrid';
import PortfolioItem from './PortfolioItem';



class Portfolio extends Component {
    state = {
        view: 'grid',
        portfolioItem: 'project1'
    }

    // // Open the project the is clicked on in grid
    // onClick = async (event) => {
    //     let getGridItems = Array.from(document.querySelectorAll('.grid-item'));
    //         console.log(getGridItems);

    //         for (let i = 0; i < getGridItems.length; i++ ) {
    //             setTimeout(function() { getGridItems[i].style.cssText = ('opacity: 0;') },  i * 100); 
    //     }
    //     let newProject = event.currentTarget.id;
    //     setTimeout(() => { this.setState({ view: 'project', portfolioItem: newProject }) }, 700)
    // }

    // Change the view back to mosiac grid
    changeToGrid = (event) => {
        this.setState({view: 'grid'});
    }
    
    render() {
        return(
            <div id="Portfolio">
                { this.state.view === "project" ? <PortfolioItem project={this.state.portfolioItem} changeToGrid={this.changeToGrid} /> : <PortfolioGrid onClick={this.onClick} /> }
            </div>
         )
    }
}

export default Portfolio;