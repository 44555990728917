import React, { Component } from 'react';
import IconLinkedin from '../assets/icon-linkedin.svg';
import IconGithub from '../assets/icon-github.svg';
import IconCodepen from '../assets/icon-codepen.svg';


function animateCSS(element, animationName, callback) {
    const node = document.querySelector(element)
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
        node.classList.remove('animated', animationName)
        node.removeEventListener('animationend', handleAnimationEnd)

        if (typeof callback === 'function') callback()
    }

    node.addEventListener('animationend', handleAnimationEnd)
  }

class Contact extends Component {

    


    inView = () => {
        const el = document.getElementById('Contact');
        var rect = el.getBoundingClientRect();
        
        if (rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */) {
         animateCSS('#Contact', 'bounce')
        }
    }

          

    render() {
        return(
            <div className="section-container" id="Contact">
                <h1 onClick={this.inView}>Let's Talk</h1>
                <p>Send me an email: <a href="mail:josh.zeilmann@outlook.com">josh.zeilmann@outlook.com</a></p>
                <a href="https://drive.google.com/file/d/1lYsd9Q97i4yM32y27GeCKddn9nn8qXPY/view?usp=sharing">
                <p>Check out my resume</p></a>
                <div className="container-social-icons">
                    <a href="https://codepen.io/jzeilmann93/" rel="noopener noreferrer"  target="_blank">
                        <img src={IconCodepen} alt="Josh Zeilmann's Codepen" className="icon-social"/>
                    </a>
                    <a href="https://www.linkedin.com/in/joshuazeilmann/" rel="noopener noreferrer"  target="_blank">
                        <img src={IconLinkedin} alt="Josh Zeilmann's Linkedin" className="icon-social"/>
                    </a>
                    <a href="https://github.com/jzeilmann93" rel="noopener noreferrer"  target="_blank">
                        <img src={IconGithub} alt="Josh Zeilmann's Github" className="icon-social"/>
                    </a>
                </div>
            </div>   
         )
    }
}

export default Contact;