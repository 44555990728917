import React, { Component } from 'react';
import HamburgerIcon from './HamburgerIcon';
import { ReactComponent as IconHome } from '../assets/icon-home.svg';
import { ReactComponent as IconAbout } from '../assets/icon-about.svg';
import { ReactComponent as IconWork } from '../assets/icon-work.svg';
import { ReactComponent as IconMail } from '../assets/icon-mail.svg';
import IconLogo from '../assets/jz-logo.svg';
import IconCodepen from '../assets/icon-codepen.svg';
import IconLinkedin from '../assets/icon-linkedin.svg';
import IconGithub from '../assets/icon-github.svg';

class Navigation extends Component {
  state = {
    toggleMenu: "hidden",
    currentView: 'Home'
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.currentView !== this.props.currentView){
        this.setState({currentView:nextProps.currentView});
    }
  }

  // Update svg color onn page change
  updateSVGColor = () => {
    const currentView = this.state.currentView;
    const icons = Array.from(document.querySelectorAll('svg[id*="nav-icon"]'));
    let arrayLocation = 0;
    switch (currentView) {
      case 'Home':
        arrayLocation = 0;
        break;
      case 'About':
        arrayLocation = 1;
        break;
      case 'Portfolio':
        arrayLocation = 2;
        break;
      case 'Contact':
        arrayLocation = 3;
        break;
      default:
        arrayLocation = 0;
        break;
    }

    for (let i = 0; i < icons.length; i++) {
      icons[i] === icons[arrayLocation] ? icons[arrayLocation].style.cssText = "fill: #1BC6B4; opacity: 1;" : icons[i].style.cssText = "fill: #fff; opacity: 1;";
    }
  }

  // Show and hide the menu on click
  toggleMenu = () => {
    const newState = this.state.toggleMenu === "show" ? "hidden" : "show";
    this.setState({ toggleMenu: newState });
  }


  componentDidMount(){
    let currentIcon = document.querySelector(`#nav-icon-${this.state.currentView}`);
    currentIcon.style.cssText = "fill: #1BC6B4; opacity: 1;"
  }


  render() {
    this.updateSVGColor();
    
    return (
      <div className="top-bar">
      <img src={IconLogo} id="logo-mobile" alt="Joshua Zeilmann"/>  
      <nav id="nav" className={this.state.toggleMenu === "hidden" ? "hidden" : "show"}>
        <img src={IconLogo} id="logo" alt="Icon Home"/> 
        <IconHome onClick={this.props.onClick} data-key="Home" id="nav-icon-Home" alt="Home" className="nav-icon" /> 
        <IconAbout onClick={this.props.onClick} data-key="About" id="nav-icon-About" alt="About" className="nav-icon" />
        <IconWork onClick={this.props.onClick} data-key="Portfolio" id="nav-icon-Portfolio" alt="Portfolio" className="nav-icon" />
        <IconMail onClick={this.props.onClick} data-key="Contact" id="nav-icon-Contact" alt="Contact" className="nav-icon" />
        <a href="https://codepen.io/jzeilmann93/" rel="noopener noreferrer"  target="_blank">
            <img src={IconCodepen} alt="Josh Zeilmann's Codepen" id="first" className="social-icon"/>
        </a>
        <a href="https://www.linkedin.com/in/joshuazeilmann/" rel="noopener noreferrer"  target="_blank">
            <img src={IconLinkedin} alt="Josh Zeilmann's Linkedin" className="social-icon"/>
        </a>
        <a href="https://github.com/jzeilmann93" rel="noopener noreferrer" target="_blank">
            <img src={IconGithub} alt="Josh Zeilmann's Github" className="social-icon"/>
        </a>
      </nav>
      <HamburgerIcon onClick={this.toggleMenu} className="nav-icon" id="hamburger-menu" />
      </div>
      )
    }
  }

  export default Navigation;
  