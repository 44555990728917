import React from 'react';

const ReactTrelloBoardContent = () => {
    return (
        <div className="project-overlay">
            <a href="https://codepen.io/jzeilmann93/pen/rqKeOr" rel="noopener noreferrer"  target="_blank">
            <p>React Trello Board</p>
            <ul>
                <li><strong>Role</strong>: Designer & Developer</li>
                <li><strong>Technology</strong>: Codepen, React.js, Focusing on Drag and Drop event handlers.</li>
                <li style={{paddingTop: 30, fontSize: 19, textDecoration: 'underline'}}>Click to view project</li>  
            </ul>
            
            </a>
        </div>  
    )
}

export default ReactTrelloBoardContent;