import React from 'react';
import Particles from 'react-particles-js';
import Params from './js/particles-params.js';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';


const Index = () => {
    return (
        <div>
            <Particles className="particles" params={Params} />
            <App />
        </div>
    )   
}

ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
