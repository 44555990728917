import React from 'react';

const HomeBySonjaContent = () => {
    return (
        <div className="project-overlay">
            <a href="https://www.homebysonja.com/" rel="noopener noreferrer"  target="_blank">
            <p>Home By Sonja</p>
            <ul>
                <li><strong>Role</strong>: Freelance Designer & Developer</li>
                <li><strong>Technology</strong>: Wordpress</li>
                <li style={{paddingTop: 30, fontSize: 19, textDecoration: 'underline'}}>Click to view project</li>      
            </ul>
            </a>
        </div>  
    )
}

export default HomeBySonjaContent;