import React from 'react';

class Home extends React.Component {
    goToAbout = () => {
        const aboutSection = document.getElementById('About');
        aboutSection.scrollIntoView();
    }

    render() {
        return(
            <div className="section-container animated fadeInLeft" id="Home">
                <div className="content">
                    <h1>Josh Zeilmann</h1>
                    <h2>Web Developer & Designer</h2>
                    <div id="top-line"></div>
                    <div id="button-line"></div>
                    <p>I build online experiences that are enjoyable, intuitive, and effective.</p>
                    <button onClick={this.goToAbout}>About Me</button>
                </div>   
            </div>
         )
    }
}

export default Home;